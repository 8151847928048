
<template>
  <div class="content" v-loading="loading">
    <swiper class="owl-carousel" :options="swiperOption" ref="mySwiper">
      <swiper-slide v-for="(item,index) in swiperImage" :key="index">
        <div :class="item.id==224?'slide d-none d-sm-block centerNoRepeat':'slide d-none d-sm-block'" :style="{background:'url('+ url + item.imgPath +') center center repeat-x'}"></div>
        <div class="slide d-block d-sm-none" :style="{background:'url('+ url + item.imgPath2 +') center center repeat-x'}"></div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <b-container fluid class="mgt30">
      <b-col md="12" class="">
        <b-row class="drs">
          <h5 class="name mgb20 tc ft21">{{$t('productfilter.new_brand_products')}}</h5>
          <ul class="brand-list" style="justify-content:center;">
            <li>
              <router-link to="/sdx-brandinfo?id=224">
                <div class="mood-img">
                  <img v-lazy="url+ 'misc/tiles/sldx-baccarat.jpg'" />
                </div>
              </router-link>

              <router-link to="/sdx-brandinfo?id=224" class="brand-photo">
                <div class="brand-photo-con">
                  <img class="b-lazy" :src="url+ 'misc/stories/logo/logo-baccarat.png'"  width="150"/>
                </div>
              </router-link>
            </li>
            <li>
              <router-link to="/sdx-brandinfo?id=489">
                <div class="mood-img">
                  <img v-lazy="url+ 'misc/tiles/sldx-frag.jpg'" />
                </div>
              </router-link>

              <router-link to="/sdx-brandinfo?id=489" class="brand-photo">
                <div class="brand-photo-con">
                  <img class="b-lazy" :src="url+ 'misc/stories/logo/logo-frag.png'"  width="150"/>
                </div>
              </router-link>
            </li>
            <li>
              <router-link to="/sdx-brandinfo?id=194">
                <div class="mood-img">
                  <img v-lazy="url+ 'misc/tiles/sldx-kaldewei.jpg'" />
                </div>
              </router-link>

              <router-link to="/sdx-brandinfo?id=194" class="brand-photo">
                <div class="brand-photo-con">
                  <img class="b-lazy" :src="url+ 'misc/stories/logo/logo-kaldewei.png'"  width="150"/>
                </div>
              </router-link>
            </li>
            <li>
              <router-link to="/sdx-brandinfo?id=sixinch">
                <div class="mood-img">
                  <img v-lazy="url+ 'misc/tiles/sldx-sixinch.jpg'" />
                </div>
              </router-link>

              <router-link to="/sdx-brandinfo?id=sixinch" class="brand-photo">
                <div class="brand-photo-con">
                  <img class="b-lazy" :src="url+ 'misc/stories/logo/logo-sixinch.png'"  width="150"/>
                </div>
              </router-link>
            </li>
          </ul>
        </b-row>

        <b-row class="drs">
          <h5 class="name mgb20 tc ft21">{{$t('productfilter.brand_products')}}</h5>
          <ul class="brand-list">
            <li v-for="(item,index) in brandData" :key="index">
              <router-link :to="{path:'/sdx-brandinfo',query:{id:item.id, collection:item.collection, status:item.status}}">
                <div class="mood-img">
                  <img v-lazy="url + item.imgPath" />
                  <div v-if="item.status=='discon'" class="discon-overlay">{{$t('sdxBrand.discontinued') }}</div>
                </div>
              </router-link>

              <router-link :to="{path:'/sdx-brandinfo',query:{id:item.id, collection:item.collection, status:item.status}}" class="brand-photo">
                <div class="brand-photo-con">
                  <img class="b-lazy" :src="url + item.imgPath2"  width="150"/>
                </div>
              </router-link>
              <!-- <div v-if="item.id==170" class="discon">{{$t('sdxBrand.discontinued') }}</div> -->

              <!-- <div class="posiR brand-type">
                <h2>AXENT</h2>

                <ul class="brand-cat">
                  <li>
                    <router-link to="#">卫浴洁具</router-link>
                  </li>
                  <li>
                    <router-link to="#">卫浴龙头</router-link>
                  </li>
                </ul>
              </div> -->
            </li>
            <li class="forJustify"></li>
            <li class="forJustify"></li>
            <li class="forJustify"></li>
            <li class="forJustify"></li>
            <li class="forJustify"></li>
            <li class="forJustify"></li>
          </ul>
        </b-row>
      </b-col>
    </b-container>
  </div>
</template>

<script>
import { getSldBrands } from "@/api/brand.js";
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    swiper,
    'swiper-slide':swiperSlide,
  },
  data() {
    return {
      url: process.env.VUE_APP_IMG_URL,
      swiperImage: [],
      swiperOption: {
        loop: false,
        autoplay: true,
        effect: "fade",
        speed: 3000,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
      },
      brandData:[],
      loading:false
    };
  },
  created() {
    this.getBrand();
  },

  methods: {
    goPage(catid, id) {
      this.$router.push(
        `/supplier-cats?catId=${catid}&id=${id}&name=${this.cattitle}`
      );
    },
    getBrand(){
      this.loading = true;
      getSldBrands().then(res=>{
        if(res.code==200){
          this.loading = false;
          let result =res.data;
          this.brandData = result.brands;

         this.swiperImage = result.slides;
         this.brandData = result.brands;
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding-top: 0px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    color: #333;
    .item {
      color: #999;
      &:hover {
        color: #950c0f;
      }
    }
  }
  .sldx {
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    @media (max-width: 767px) {
      margin: 0 -15px;
      .brand-cat li {
        padding-right: 7px;
        margin-right: 3px;
      }

    }


  }
  .slide {
    height: 450px !important;
    background-size: cover !important;
    @media (max-width: 1200px) {
      height: 400px !important;
    }
    @media (max-width: 758px) {
      height: 300px !important;
    }
    @media (max-width: 375px) {
      height: 250px !important;
    }
    &.centerNoRepeat {
      @media (min-width: 1921px) {
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-color: #000 !important;
      }
    }
  }
}
.brand-list {
  margin: 0 auto;
  /* max-width: 1100px; */
  width: 100%;
  padding: 0;
  text-align: center;
  // text-align: justify;
  font-size: 0;
  margin-bottom: 20px;
  max-width: 1560px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1559px) {
    padding: 0 15px;
  }
  @media (max-width: 1219px) {
  }
  @media (max-width: 767px) {
    padding: 0 7.5px;
  }
  li{
    box-sizing: border-box;
    text-align: center;
    font: bold 18px sans-serif;
    width: 16.666%;
    margin: 0px 0px 20px 0;
    padding: 25px 30px;
    position: relative;
    @media (max-width: 1559px) {
      padding: 25px 15px;
    }
    @media (max-width: 1219px) {
      width: 25%;
    }
    @media (max-width: 767px) {
      width: 50%;
      margin: 0 0 15px 0;
      padding: 0 7.5px;
      vertical-align: top;
      .brand-type{
        h2{ font-size: 16px;}
      }
    }
    .mood-img {
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-bottom: 15px;
      @media (max-width: 567px) {
        margin-bottom: 5px;
      }
      img{
        width: 100%;
        height: auto;
        transition: all 1s;
        transition-timing-function: ease;
      }
      .discon-overlay {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.85);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        // transition: all 0.5s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
    }
    .brand-photo{
      position: relative;
      display: block;
      text-align: center;
      /* box-shadow: 0 0 10px 5px #f3f3f3 inset; */
      width: 100%;
      margin-bottom: 12px;
      /* padding: 10px 10px; */
      padding: 0px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;
      &::before{
        content: "";
        display: block;
        padding-top: 30%;
      }
      .brand-photo-con{
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        align-items: center;
        img{
          display: block;
          max-width: 90%;
          max-height: 80%;
          margin: auto;
          width: auto;
          height: auto;
        }
      }

    }
    .discon {
      margin-top: -12px;
      font-size: 14px;
      color: #999999;
    }
    .brand-type{
      h2{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        font-size: 18px;
        max-width: 100%;
        display: none;

      }
      .brand-cat{
        margin: 6px 0px 0 0;
        padding: 0px;
        display: none;
        li{
          width: auto;
          font-size: 12px;
          font-weight: normal;
          color: #999999;
          border-right: 1px solid #CCCCCC;
          padding: 0;
          padding-right: 10px;
          line-height: 12px;
          margin: 0 5px 5px 0px;
          color: #999;
        }
      }
    }
    &:hover{
      .mood-img {
        img{
          transform: scale(1.1);
          @media (max-width: 567px) {
            transform: none;
          }
        }
        .discon-overlay {
          // font-size: 13px;
          // height: 14%;
          color: #555555;
          opacity: 0;
          @media (max-width: 567px) {
            opacity: 1;
          }
        }
      }
    }
  }
  .forJustify {
    border: none;
    background: none;
    height: 1px;
    padding: 0px;
    margin: 0;
    /* margin: 0 2% 0 0; */
  }
}
.swiper-pagination {
  position: static;
  margin-top: 20px;
  padding: 0 15px;
  span {
    cursor: pointer;
  }
  @media (max-width: 567px) {
    margin-top: 5px;
    display: flex;
    justify-content: center;
  }
}
/deep/ .swiper-pagination-bullet {
  width: 50px;
  border-radius: 0;
  opacity: 1;
  margin: 0 2px !important;
  padding: 15px 0;
  background: none;
  @media (max-width: 1024px) {
    width: 40px;
  }
  @media (max-width: 758px) {
    max-width: 30px;
  }
  &::before {
    display: block;
    width: 100%;
    height: 2px;
    background: #d6d6d6;
    content: " ";
  }
  &:hover::before {
    background: #869791;
  }
}
/deep/ .swiper-pagination-bullet-active {
  background: none;
  &::before {
    background: #869791;
  }
}
</style>
