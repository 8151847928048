<template>
  <div class="content" v-loading="loading">
    <b-container fluid class="deliverable-details">
      <b-row>
        <b-col xs="12">
          <div class="name mgb20 tc">
            {{ $t("welcome.design_deliverable") }}
          </div>
          <div class="deliverable-details-div flex-start">
            <div class="brand-img tl">
              <template v-if="newSwiperImage">
                <swiper
                  class="owl-carousel"
                  :options="swiperOption"
                  ref="mySwiper"
                  @slideChange="slideChange"
                >
                  <swiper-slide
                    class="swiperslide"
                    v-for="(item, index) in newSwiperImage"
                    v-show="item.parent_id == itemId"
                    :key="index"
                  >
                    <div class="slide">
                      <img :src="url + item.path" />
                    </div>
                  </swiper-slide>
                </swiper>
              </template>
              <div
                v-else
                style="
                  background: #f2f2f2;
                  height: 400px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 15px;
                  color: #ccc;
                "
              >
                {{ detailinfo.name }}
              </div>

              <div class="coCat tl mgt10">
                <el-select
                  popper-append-to-body
                  :style="{ width: selectOptionWidth }"
                  v-model="opt_area_id"
                  @change="getchangevalue"
                >
                  <el-option
                    v-for="(item, key) in opt_area"
                    :key="key"
                    @click.native="setOptionWidth"
                    :label="item"
                    :value="key"
                    ><span style="float: left">{{ item }}</span>
                  </el-option>
                </el-select>
              </div>
              <ul class="thumbs" style="width: 100%; margin: 0 auto">
                <li
                  v-for="(item, index) in newSwiperImage"
                  :key="index"
                  v-show="item.parent_id == itemId"
                  @click="thumbchangeIndex(index)"
                >
                  <div
                    class="thumb-img"
                    :class="slideIndex == index ? 'selectd' : ''"
                  >
                    <div>
                      <img :src="url + item.path + '/s300'" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="brand-info">
              <h1>{{ detailinfo.name }}</h1>
              <div class="specs">
                <div class="tr">
                  <div class="th">{{ $t("welcome.project_location") }}:</div>
                  <div class="td">{{ detailinfo.location }}</div>
                </div>
                <div class="tr">
                  <div class="th">{{ $t("welcome.project_client") }}:</div>
                  <div class="td">{{ detailinfo.developer }}</div>
                </div>
              </div>
              <!-- @tab-click="handleClick" -->
              <el-tabs v-model="activeName" @tab-click="getTabsId">
                <el-tab-pane
                  :label="item.project_no"
                  :name="'tab' + item.id"
                  v-for="(item, index) in allItems"
                  :key="index"
                >
                  <div class="tab-content">
                    <div class="specs">
                      <div class="tr">
                        <div class="th">{{ $t("welcome.project_grade") }}:</div>
                        <div class="td">
                          <span v-if="item.asterisk == '#'">{{
                            $t("welcome.project_grade_important")
                          }}</span>
                          <span v-else-if="item.asterisk == '*'">{{
                            $t("welcome.project_grade_quality")
                          }}</span>
                          <span v-else-if="item.asterisk == '**'">{{
                            $t("welcome.project_grade_premium")
                          }}</span>
                          <span v-else>{{
                            $t("welcome.project_grade_general")
                          }}</span>
                        </div>
                      </div>

                      <div class="tr">
                        <div class="th">{{ $t("welcome.project_type") }}:</div>
                        <div
                          class="td"
                          v-for="(type, key) in opt_type"
                          :key="key"
                          v-show="item.opt_type_ids == key"
                        >
                          <span v-if="item.opt_type_ids == key">
                            {{ type }}
                          </span>
                        </div>
                      </div>

                      <div class="tr hidden" v-if="item.construct">
                        <div class="th">
                          {{ $t("welcome.project_contractor") }}:
                        </div>
                        <div class="td">{{ item.construct }}</div>
                      </div>

                      <template
                        v-if="
                          scopes && scopes[itemId] && scopes[itemId].length > 0
                        "
                      >
                        <div
                          class="tr"
                          v-for="(scope, index) in scopes[itemId]"
                          :key="index"
                        >
                          <div class="th">
                            {{
                              index == 0
                                ? $t("welcome.project_design_scope") + ":"
                                : ""
                            }}
                          </div>
                          <div class="td">{{ scope.name_tc }}</div>
                          <div class="th">
                            {{ $t("welcome.project_design_area") }}:
                          </div>
                          <div class="td">
                            {{ scope.area_size }}
                            {{ $t("welcome.project_rmb_per_sqm") }}
                          </div>
                        </div>
                      </template>
                      <div class="tr">
                        <div class="th">
                          {{ $t("welcome.project_requirements") }}:
                        </div>
                        <div class="td">
                          {{ item.design_req_sc || "-" }}
                        </div>
                      </div>

                      <div class="tr" v-if="item.brand_ids == 4">
                        <div class="th">
                          {{ $t("welcome.project_team_construct") }}:
                        </div>
                        <div
                          class="td"
                          v-if="
                            item.sla_team &&
                            sla_team &&
                            sla_team[itemId] &&
                            sla_team[itemId].length > 0
                          "
                        >
                          <span
                            v-for="(team, inx) in sla_team[itemId]"
                            :key="inx"
                          >
                            <span v-if="inx == 0">
                              <span v-if="team.company != '-'"
                                >{{ team.company }}{{ team.loc }} -
                                {{ team.initial }}
                              </span>
                              <span v-else
                                >{{ team.english_name }} {{ team.lastname }}
                                {{ team.initial }}
                              </span>
                            </span>
                            <span v-else>
                              /
                              <span v-if="team.company != '-'"
                                >{{ team.company }}{{ team.loc }} -
                                {{ team.initial }}
                              </span>
                              <span v-else
                                >{{ team.english_name }} {{ team.lastname }}
                                {{ team.initial }}
                              </span>
                            </span>
                          </span>
                        </div>
                        <div class="td" v-else>-</div>
                      </div>
                      <template v-else>
                        <div class="tr">
                          <div class="th">
                            {{ $t("welcome.project_team_interior") }}:
                          </div>
                          <div class="td">
                            <div
                              v-if="
                                ddInfo &&
                                ddInfo[itemId] &&
                                ddInfo[itemId].length > 0
                              "
                            >
                              <span
                                v-for="(team, inx) in ddInfo[itemId]"
                                :key="inx"
                              >
                                <span v-if="inx == 0">
                                  <span v-if="team.company != '-'"
                                    >{{ team.company }}{{ team.loc }} -
                                    {{ team.initial }}
                                  </span>
                                  <span v-else
                                    >{{ team.english_name }}
                                    {{ team.lastname }} {{ team.initial }}
                                  </span>
                                </span>
                                <span v-else>
                                  /
                                  <span v-if="team.company != '-'"
                                    >{{ team.company }}{{ team.loc }} -
                                    {{ team.initial }}
                                  </span>
                                  <span v-else
                                    >{{ team.english_name }}
                                    {{ team.lastname }} {{ team.initial }}
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div v-else>-</div>
                          </div>
                          <div class="th">
                            {{ $t("welcome.project_team_four_cat") }}:
                          </div>
                          <div class="td">
                            <div
                              v-if="
                                ffe && ffe[itemId] && ffe[itemId].length > 0
                              "
                            >
                              <span
                                v-for="(team, inx) in ffe[itemId]"
                                :key="inx"
                              >
                                <span v-if="inx == 0">
                                  <span v-if="team.company != '-'"
                                    >{{ team.company }}{{ team.loc }} -
                                    {{ team.initial }}
                                  </span>
                                  <span v-else
                                    >{{ team.english_name }}
                                    {{ team.lastname }} {{ team.initial }}
                                  </span>
                                </span>
                                <span v-else>
                                  /
                                  <span v-if="team.company != '-'"
                                    >{{ team.company }}{{ team.loc }} -
                                    {{ team.initial }}
                                  </span>
                                  <span v-else
                                    >{{ team.english_name }}
                                    {{ team.lastname }} {{ team.initial }}
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div v-else>-</div>
                          </div>
                        </div>
                        <div class="tr">
                          <div class="th">
                            {{ $t("welcome.project_team_ffne") }}:
                          </div>
                          <div class="td">
                            <div
                              v-if="aa && aa[itemId] && aa[itemId].length > 0"
                            >
                              <span
                                v-for="(team, inx) in aa[itemId]"
                                :key="inx"
                              >
                                <span v-if="inx == 0">
                                  <span v-if="team.company != '-'"
                                    >{{ team.company }}{{ team.loc }} -
                                    {{ team.initial }}
                                  </span>
                                  <span v-else
                                    >{{ team.english_name }}
                                    {{ team.lastname }} {{ team.initial }}
                                  </span>
                                </span>
                                <span v-else>
                                  /
                                  <span v-if="team.company != '-'"
                                    >{{ team.company }}{{ team.loc }} -
                                    {{ team.initial }}
                                  </span>
                                  <span v-else
                                    >{{ team.english_name }}
                                    {{ team.lastname }} {{ team.initial }}
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div v-else>-</div>
                          </div>

                          <div class="th">
                            {{ $t("welcome.project_team_implement") }}:
                          </div>
                          <div class="td">
                            <div
                              v-if="
                                ffexec &&
                                ffexec[itemId] &&
                                ffexec[itemId].length > 0
                              "
                            >
                              <span
                                v-for="(team, inx) in ffexec[itemId]"
                                :key="inx"
                              >
                                <span v-if="inx == 0">
                                  <span v-if="team.company != '-'"
                                    >{{ team.company }}{{ team.loc }} -
                                    {{ team.initial }}
                                  </span>
                                  <span v-else
                                    >{{ team.english_name }}
                                    {{ team.lastname }} {{ team.initial }}
                                  </span>
                                </span>
                                <span v-else>
                                  /
                                  <span v-if="team.company != '-'"
                                    >{{ team.company }}{{ team.loc }} -
                                    {{ team.initial }}
                                  </span>
                                  <span v-else
                                    >{{ team.english_name }}
                                    {{ team.lastname }} {{ team.initial }}
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div v-else>-</div>
                          </div>
                        </div>
                      </template>

                      <div class="tr" v-if="item.brand_ids == 6">
                        <div class="th">{{ $t("welcome.project_scope") }}:</div>
                        <div class="td">
                          <span v-if="item.mode_ids != null">
                            <span
                              v-for="(mode, modeIndex) in item.mode_ids.split(
                                ','
                              )"
                              :key="modeIndex"
                              >{{ optModeList[mode] }}
                              <span
                                v-if="
                                  modeIndex !=
                                  item.mode_ids.split(',').length - 1
                                "
                                >/</span
                              >
                            </span>
                          </span>
                          <span v-else> - </span>
                        </div>
                      </div>
                      <template v-if="item.brand_ids == 4">
                        <div class="tr">
                          <div class="th">
                            {{ $t("welcome.project_budget_construct") }}:
                          </div>

                          <div
                            class="td"
                            v-if="
                              scopes &&
                              scopes[itemId] &&
                              scopes[itemId].length > 0
                            "
                          >
                            <span
                              v-for="(scope, index) in scopes[itemId]"
                              :key="index"
                            >
                              <span v-if="scope.construct_budget > 0">
                                {{ scope.name }}
                                {{ construct_budget | toThousandFilter }} /
                                {{ $t("welcome.project_rmb_per_sqm") }}
                              </span>
                            </span>
                          </div>
                        </div>
                        <div class="tr">
                          <div class="th">
                            {{ $t("welcome.project_cost_construct") }}:
                          </div>
                          <div
                            class="td"
                            v-if="
                              scopes &&
                              scopes[itemId] &&
                              scopes[itemId].length > 0
                            "
                          >
                            <span
                              v-for="(scope, index) in scopes[itemId]"
                              :key="index"
                              ><span>{{ scope.name }}</span>
                              <span v-if="scope.construct_final > 0">
                                {{ construct_final | toThousandFilter }} /
                              </span>
                              <span v-else> - </span>
                              <span v-if="scope.construct_final > 0">{{
                                $t("welcome.project_rmb_per_sqm")
                              }}</span>
                            </span>
                          </div>
                        </div>
                      </template>
                      <template
                        v-if="item.brand_ids != 4 && item.brand_ids != 6"
                      >
                        <div class="tr">
                          <div class="th">
                            {{ $t("welcome.project_budget_fitting_out") }}:
                          </div>
                          <div
                            class="td"
                            v-if="
                              scopes &&
                              scopes[itemId] &&
                              scopes[itemId].length > 0
                            "
                          >
                            <div
                              v-for="(scope, index) in scopes[itemId]"
                              :key="index"
                            >
                              {{ scope.name }}
                              <span v-if="scope.fittingout_budget > 0">
                                {{ item.currency_symbol
                                }}{{
                                  scope.fittingout_budget | toThousandFilter
                                }}
                              </span>
                              <span v-else> - </span>
                              <span v-if="scope.fittingout_budget > 0"
                                >{{ item.currency }}/
                                {{ $t("welcome.project_rmb_per_sqm") }}</span
                              >

                              <span>{{
                                scope.fittingout_budget_inc_equip == 0
                                  ? $t("welcome.project_equip_excluded")
                                  : ""
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="tr">
                          <div class="th">
                            {{ $t("welcome.project_cost_fitting_out") }}:
                          </div>
                          <div
                            class="td"
                            v-if="
                              scopes &&
                              scopes[itemId] &&
                              scopes[itemId].length > 0
                            "
                          >
                            <div
                              v-for="(scope, index) in scopes[itemId]"
                              :key="index"
                            >
                              {{ scope.name }}
                              <span v-if="scope.fittingout_final > 0">
                                {{ item.currency_symbol
                                }}{{
                                  scope.fittingout_final | toThousandFilter
                                }}
                                {{ item.currency }}/{{
                                  $t("welcome.project_rmb_per_sqm")
                                }}
                              </span>
                              <span v-else> - </span>

                              <span>{{
                                scope.fittingout_final_inc_equip == 0
                                  ? $t("welcome.project_equip_excluded")
                                  : ""
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </template>

                      <template v-if="item.brand_ids == 6">
                        <div class="tr">
                          <div class="th">
                            {{ $t("welcome.project_budget_ffne") }}:
                          </div>
                          <div
                            class="td"
                            v-if="
                              scopes &&
                              scopes[itemId] &&
                              scopes[itemId].length > 0
                            "
                          >
                            <span
                              style="display: block"
                              v-for="(scope, index) in scopes[itemId]"
                              :key="index"
                            >
                              {{ scope.name }}
                              <span v-if="scope.ffe_budget > 0">
                                {{ scope.ffe_budget | toThousandFilter }} /
                              </span>
                              <span v-else> - </span>
                              <span v-if="scope.ffe_budget > 0">{{
                                $t("welcome.project_rmb_per_sqm")
                              }}</span>

                              <span>{{
                                scope.ffe_budget_inc_equip == 0
                                  ? $t("welcome.project_equip_excluded")
                                  : ""
                              }}</span>
                            </span>
                          </div>
                        </div>
                        <div class="tr">
                          <div class="th">
                            {{ $t("welcome.project_cost_ffne") }}:
                          </div>
                          <div
                            class="td"
                            v-if="
                              scopes &&
                              scopes[itemId] &&
                              scopes[itemId].length > 0
                            "
                          >
                            <span
                              style="display: block"
                              v-for="(scope, index) in scopes[itemId]"
                              :key="index"
                            >
                              {{ scope.name }}
                              <span v-if="scope.ffe_final > 0">
                                {{ scope.ffe_final | toThousandFilter }} /
                              </span>
                              <span v-else> - </span>
                              <span v-if="scope.ffe_final > 0">{{
                                $t("welcome.project_rmb_per_sqm")
                              }}</span>

                              <span>{{
                                scope.ffe_final_inc_equip == 0
                                  ? $t("welcome.project_equip_excluded")
                                  : ""
                              }}</span>
                            </span>
                          </div>
                        </div>
                      </template>

                      <div
                        class="tr"
                        v-if="item.brand_ids != 4 && item.brand_ids != 6"
                      >
                        <div class="th">
                          {{ $t("welcome.project_new_style") }}:
                        </div>
                        <div class="td">
                          {{ item.newstyle || " -" }}
                        </div>
                      </div>
                      <div v-if="subCompanies && subCompanies[item.project_id]">
                        <div
                          class="tr"
                          v-for="(subCom, index) in Object.keys(
                            subCompanies[item.project_id]
                          )"
                          :key="index"
                        >
                          <div class="th">{{ subCom }}:</div>
                          <div
                            class="td"
                            v-if="
                              subCompanies[item.project_id][subCom] &&
                              subCompanies[item.project_id][subCom].length > 0
                            "
                          >
                            <div
                              v-for="(subChild, indexChild) in subCompanies[
                                item.project_id
                              ][subCom]"
                              :key="indexChild"
                            >
                              <span v-if="subChild.name">
                                <a
                                  class="link-style"
                                  @click="
                                    $router.push({
                                      path: '/supplierdetail',
                                      query: { id: subChild.id }
                                    })
                                  "
                                >
                                  {{ subChild.name }}
                                  <i class="fa fa-link fa-flip-horizontal"></i>
                                </a>
                              </span>
                            </div>
                          </div>
                          <div v-else class="td">-</div>
                        </div>
                      </div>

                      <!-- Task: show the SC projects company by type in Deliverable Page -->
                    </div>
                    <div
                      class="specs"
                      v-if="dates && dates[itemId] && dates[itemId].length > 0"
                    >
                      <div
                        class="tr"
                        v-for="(dates, key) in dates[itemId]"
                        :key="key"
                      >
                        <div class="th">
                          {{ optTpList[dates.opt_timepoint] }}:
                        </div>
                        <div class="td">
                          <div v-if="dates.opt_timepoint == 4">
                            {{ dates.date_input }}
                          </div>
                          <div v-else>
                            {{ dates.date_input }}
                          </div>
                        </div>
                      </div>
                      <div class="tr">
                        <div class="th">
                          {{ $t("welcome.project_photo_shooting") }}:
                        </div>
                        <div
                          class="td"
                          style="cursor: pointer"
                          v-if="
                            item.design_res_list.length > 0 &&
                            item.design_res_list[0].ref_id == 24
                          "
                        >
                          <a @click="toDesign(item.design_res_list[0])"
                            >{{ $t("welcome.project_photo_shooting_pro")
                            }}<i class="fa fa-link fa-flip-horizontal"></i
                          ></a>
                        </div>
                        <div
                          class="td"
                          style="cursor: pointer"
                          v-if="
                            item.design_res_list.length > 0 &&
                            item.design_res_list[0].ref_id == 26
                          "
                        >
                          <a @click="toDesign(item.design_res_list[0])"
                            >{{ $t("welcome.project_photo_shooting_internal")
                            }}<i class="fa fa-link fa-flip-horizontal"></i
                          ></a>
                        </div>
                        <div
                          class="td"
                          style="cursor: pointer"
                          v-if="
                            item.design_res_list.length > 0 &&
                            item.design_res_list[0].ref_id == 25
                          "
                        >
                          <a @click="toDesign(item.design_res_list[0])"
                            >{{ $t("welcome.project_photo_shooting_second")
                            }}<i class="fa fa-link fa-flip-horizontal"></i
                          ></a>
                        </div>
                        <div class="td" v-else-if="!item.design_res_list">
                          -
                        </div>
                      </div>
                    </div>

                    <div class="specs" v-if="guildline[0].length > 0">
                      <div class="tr">
                        <div class="th">{{ $t("welcome.guildline") }}:</div>
                        <div class="td">
                          <div
                            class="file"
                            v-for="file in guildline"
                            :key="file.id"
                          >
                            <div class="file-info">
                              <a :href="url + file.path" v-if="file.path">
                                {{ file.name }}
                                <i class="fa fa-file-text-o"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="files" v-else>
                      {{ $t("welcome.file_preview_download") }}:

                      <template v-if="isExternal == false">
                        <div
                          v-loading="pdfLoading"
                          v-if="
                            attachments &&
                            attachments[itemId] &&
                            attachments[itemId].length
                          "
                        >
                          <div
                            class="file"
                            v-for="(childAttch, childIndex) in attachments[
                              itemId
                            ]"
                            :key="childIndex"
                          >
                            <div class="file-name">
                              {{ childAttch.filename }}
                            </div>
                            <div class="file-info is-files">
                              <div>{{ childAttch.file_size }}</div>
                              <div
                                style="
                                  width: 45px;
                                  text-transform: uppercase;
                                  text-align: center;
                                "
                              >
                                {{ childAttch.file_ext }}
                              </div>
                            </div>
                            <div class="file-actions is-actions">
                              <div class="pdf-actions">
                                <span
                                  @click="openPDF(childAttch.id, 'preview')"
                                  v-if="childAttch.file_ext == 'pdf'"
                                  ><i class="fa fa-search"></i
                                ></span>
                              </div>
                              <div>
                                <span
                                  @click="
                                    openUrlPDF(
                                      childAttch.id,
                                      'upload',
                                      childAttch.filename,
                                      childAttch.file_ext
                                    )
                                  "
                                >
                                  <i class="fa fa-download"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>

                      <div class="file" v-else>
                        <div class="file-name">
                          {{ $t("welcome.file_no_external") }} {{ " ip: "+ userIp }}
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <pdf-view
      :pdf-loading="pdfLoading"
      @pdfChange="pdfChange"
      :pdf-dialog-visible="pdfDialogVisible"
      :pdf-src="pdfSrc"
      :error-text="errorText"
    />
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { getdeliverablesdetail, getFiles, getUrls } from "@/api/design";
import { strftime, strtotime } from "@/utils/date";
import pdfView from "@/components/PdfView.vue";
export default {
  components: {
    swiper,
    "swiper-slide": swiperSlide,
    "pdf-view": pdfView
  },
  data() {
    return {
      pdfLoading: false,
      pdfDialogVisible: false,
      selectOptionWidth: "55px",
      newSwiperImage: [],
      mid: null,
      swiperOption: {
        loop: false
      },
      slideIndex: 0,
      swiperIndex: "",
      loading: false,
      url: process.env.VUE_APP_IMG_URL,
      setObjUrl: process.env.VUE_APP_BASE_API,
      opt_area: [],
      opt_area_id: "",
      detailinfo: [],
      allItems: [],
      scopes: [],
      sla_team: {},
      ddInfo: {},
      aa: {},
      ffe: {},
      ffexec: {},
      guildline: [],
      isExternal: "",
      userIp: "",
      attachments: {},
      swiperImage: {},
      optTpList: {},
      optModeList: {},
      itemId: "",
      imgFirstIndex: 0,
      rid: "",
      activeName: "",
      pdf_id: "",
      pdfSrc: "",
      errorText: "",
      subCompanies: {}
    };
  },

  created() {
    this.mid = this.$route.query.id;
    this.rid = this.$route.query.relateId;
    if (!this.itemId) {
      this.itemId = this.mid;
    }
    this.tabName = this.rid ? this.rid : this.mid;
    this.activeName = "tab" + this.tabName;
    this.itemId = this.tabName;
    this.getDetails();
  },
  computed: {
    showImg() {
      return function (item) {
        if (item.parent_id == this.itemId) {
          return true;
        }
      };
    },
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  filters: {
    formatCreateTime(fomate, time) {
      return strftime("%b %Y", time);
    },
    strtotime(time) {
      return strtotime(time);
    }
  },
  methods: {
    toDesign(item) {
      this.$router.push(
        `/project-photo-album?id=${item.ref_id}&refId=${item.id}`
      );
    },
    openPDF(id, type, filename, ext) {
      this.pdfSrc = "";
      this.pdfLoading = true;
      if (type == "preview") {
        this.errorText = "";
        this.pdfDialogVisible = true;
      }
      const data = {
        target: "deliverableattach",
        id: id,
        act: type == "preview" ? "rd" : "dl"
      };
      getFiles(data)
        .then((res) => {
          this.pdfSrc = this.getObjectURL(res, type);
          if (type === "upload") {
            let fileName = filename + "." + ext;
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = this.pdfSrc;
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          }
          this.pdfLoading = false;
        })
        .catch((error) => {
          this.errorText = this.$t("welcome.pdf_error");
          this.pdfLoading = false;
        });
    },
    openUrlPDF(id, type, filename, ext) {
      this.pdfSrc = "";
      this.pdfLoading = true;
      if (type == "preview") {
        return
      }
      // return
      const data = {
        target: "deliverableattach",
        id: id,
        act: "dl",
        mode: "url"
      };
      getUrls(data)
        .then((res) => {
          this.pdfSrc = res;
          if (type === "upload") {
            let fileName = filename + "." + ext;
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = this.pdfSrc;
            document.body.appendChild(elink);
            elink.click();
            document.body.removeChild(elink);
          }
          this.pdfLoading = false;
        })
        .catch((error) => {
          this.errorText = this.$t("welcome.pdf_error");
          this.pdfLoading = false;
        });
    },
    getObjectURL(file) {
      let url = null;
      let fileData = [];
      fileData.push(file);
      let blob = new Blob(fileData, { type: file.type });

      if (window.URL.createObjectURL != undefined) {
        // basic
        url = window.URL.createObjectURL(blob);
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        try {
          url = window.webkitURL.createObjectURL(blob);
        } catch (error) {
          console.log(error);
        }
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        try {
          url = window.URL.createObjectURL(blob);
        } catch (error) {
          console.log(error);
        }
      }
      return url;
    },
    pdfChange(val) {
      this.pdfDialogVisible = val;
    },
    setOptionWidth(event) {
      this.$nextTick(() => {
        if (event.target.localName == "span") {
          this.nodeIndex = event.target.clientWidth + 30;
        } else {
          this.nodeIndex = event.target.childNodes[0].clientWidth + 30;
        }

        this.selectOptionWidth = this.nodeIndex + "px";
      });
    },
    getTabsId(tab) {
      this.allItems.map((el, index) => {
        if (tab.$options.propsData.label == el.project_no) {
          this.itemId = el.id;
        }
      });
      const indexData = [];
      this.newSwiperImage.map((imgItem, imgIndex) => {
        if (this.itemId == imgItem.parent_id) {
          indexData.push(imgIndex);
        }
      });
      this.imgFirstIndex = indexData[0];
      this.slideIndex = this.imgFirstIndex;
      this.swiper.slideTo(0);
    },
    getDetails() {
      this.loading = true;
      getdeliverablesdetail(this.mid, { area: this.opt_area_id }).then(
        (res) => {
          this.loading = false;
          let data = res.data;
          this.detailinfo = data.item;
          this.allItems = data.allItem;
          this.opt_type = data.optTypeList;
          this.scopes = data.scopes;
          this.sla_team = data.sla_team;
          this.ddInfo = data.ddInfo;
          this.aa = data.aa;
          this.ffe = data.ffe;
          this.ffexec = data.ffexec;
          this.guildline = this.changeArr(data.guildline);
          this.dates = data.dates;
          this.isExternal = data.isExternal;
          this.userIp = data.userIp??"";
          this.attachments = data.attachments;
          this.swiperImage = data.images;
          this.opt_area = data.optAreaList;
          this.optTpList = data.optTpList;
          this.optModeList = data.optModeList;
          this.subCompanies = data.subCompanies;
          const newImg = [];
          Object.keys(this.swiperImage).map((el) => {
            this.swiperImage[el].map((imgEl) => {
              newImg.push(imgEl);
            });
          });
          this.newSwiperImage = newImg;
          const indexData = [];
          this.newSwiperImage.map((imgItem, imgIndex) => {
            if (this.itemId == imgItem.parent_id) {
              indexData.push(imgIndex);
            }
          });
          this.imgFirstIndex = indexData[0];
          this.slideIndex = this.imgFirstIndex;
        }
      );
    },
    goPage(url) {
      this.$router.push(url);
    },
    getchangevalue() {
      this.getDetails();
    },
    changeArr(list) {
      let arr = [];
      for (let i in list) {
        arr.push(list[i]);
      }
      return arr;
    },
    slideChange() {
      this.slideIndex = this.swiper.activeIndex + this.imgFirstIndex;
    },
    thumbchangeIndex(index) {
      this.slideIndex = index;
      this.swiperIndex = index - this.imgFirstIndex;
      this.swiper.slideTo(this.swiperIndex);
    },
    goDesign(id, refId) {
      this.$router.push(`/project-photo-album?id=${refId}&refId=${id}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
  }
}
/deep/ .el-input__inner {
  border: none;
  font-size: 13px;
  color: #555;
  text-align: center;
  padding-left: 0;
}

.deliverable-details-div {
  max-width: 1530px;
  margin: 0 auto;
  @media (max-width: 767px) {
    display: block;
  }
  // text-align: center;
  .brand-img {
    // float: left;
    position: relative;
    min-height: 1px;
    padding-right: 20px;
    padding-left: 15px;
    width: 50%;
    @media (min-width: 1300px) {
      width: 50%;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 25px;
      padding-right: 15px;
      overflow: hidden;
      .img-caption {
        text-align: center;
      }
    }
    .thumbs {
      li {
        z-index: 0;
        display: inline-block;
        width: 13.8%;
        text-align: center;
        font-size: 15px;
        margin: 0px 0px 1% 0;
        padding: 6px;
        vertical-align: top;
        position: relative;
        .thumb-img {
          position: relative;
          text-align: center;
          width: 100%;
          padding: 0px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          cursor: pointer;
          overflow: hidden;
          &::before {
            margin: 0 auto;
            content: "";
            display: block;
            padding-top: 100%;
          }
          > div {
            position: absolute;
            display: flex;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            align-items: center;
            padding: 2px;
            img {
              display: block;
              max-width: 100%;
              max-height: 100%;
              margin: auto;
              width: auto;
              height: auto;
              transition: all 0.3s ease-in-out;
              -webkit-transition: all 0.3s ease-in-out;
              -moz-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              border: none;
            }
          }
        }
        .selectd {
          border: 1px solid #aaa;
        }
      }
    }
    .co-cat {
      display: inline-block;
    }
    .owl-carousel {
      .swiperslide {
        &::before {
          margin: 0 auto;
          content: "";
          display: block;
          padding-top: 56%;
        }
        .slide {
          // padding: 0 12%;
          position: absolute;
          display: flex;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          align-items: center;
          img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
            width: auto;
            height: auto;
            transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }
  .brand-info {
    text-align: left;
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 20px;
    position: unset;
    /* padding-bottom: 100px; */
    flex: 1;
    width: auto;
    max-width: none;
    h1 {
      font-size: 25px;
      margin: 0px 0px 20px;
    }
    .specs {
      color: #999999;
      margin-bottom: 15px;
      font-size: 14px;
      .tr {
        font-weight: normal;
        color: #999999;
        display: flex;
        text-align: left;
        .th {
          color: #999;
          max-width: 158px;
          flex: auto;
          padding-bottom: 5px;
        }
        .td {
          color: #555;
          flex: 1;
          padding-bottom: 5px;
          padding-right: 10px;
        }
      }
    }
    .files {
      color: #999;
      font-size: 14px;
      .file {
        padding-top: 5px;
        display: flex;
        color: #999999;
        align-items: flex-start;
        .file-name {
          flex: auto;
          padding-right: 15px;
        }
        > div,
        i {
          color: #555;
        }
      }
    }
  }
}
/deep/ .el-tabs__nav-wrap {
  &:after {
    bottom: 1px;
    height: 1px;
    background-color: #e7e7e7;
  }
}
/deep/ .el-tabs__item.is-active {
  color: #555;
}
/deep/ .el-tabs__active-bar {
  background: #888;
}
.href:hover {
  color: #950c0f;
}
.is-files {
  font-size: 12px;
  min-width: 145px;
  display: flex;
  justify-content: end;
  margin-top: 4px;
  padding-right: 10px;
  line-height: 12px;
}
.is-actions {
  font-size: 12px;
  min-width: 60px;
  display: flex;
  margin-top: 3px;
  border-left: 1px solid #ddd;
  line-height: 12px;
  padding-left: 10px;
  div {
    width: 25px;
    span {
      padding: 5px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        color: #950c0f;
        i {
          color: #950c0f !important;
        }
      }
    }
  }
}
.link-style:hover {
  color: #950c0f;
  cursor: pointer;
}
</style>
