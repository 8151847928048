import axios from 'axios'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import md5 from 'js-md5'
import Vue from 'vue'
import i18n from '@/lang'
import { getAccessToken } from '@/utils/auth'

// process.env.VUE_APP_BASE_API
// create an axios instance
const service = axios.create({
  baseURL:process.env.VUE_APP_BASE_API , // url = base url + request url
  // baseURL:'/api' , // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 3000000, // request timeout
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

// request interceptor
service.interceptors.request.use(
  async (config) => {
    // do something before request is sent
    const time = moment
      .tz(moment(), 'Asia/Hong_Kong')
      .format('YYYY-MM-DD HH:mm:ss')
    // const secret = md5(`206734cf-ed78-423c-8e42-f35e3dac2869:SLD Group System:${time}`)
    var secret = md5(process.env.VUE_APP_API_APP_SECRET + ':' + process.env.VUE_APP_API_APP_NAME + ':' + time)
    config.headers['secret'] = secret
    config.headers['request-time'] = time
    config.headers['app-id'] = process.env.VUE_APP_API_APP_ID
    config.headers['Accept-Language'] = localStorage.getItem('language');
    config.headers['accept-region'] = localStorage.getItem('forRegion');

    if(config.url.startsWith("/get-file")){
      config.responseType="blob"
    }

    // handle different axios version
    let hasAuthorization = false;
    if (getAccessToken()) {
      // get the latest token for this request
      if (Object.prototype.hasOwnProperty.call(config.headers, 'Authorization')) {
        // console.log("new axios");
        config.headers['Authorization'] = `Bearer ${getAccessToken()}`;
        hasAuthorization = true;
      } else if (Object.prototype.hasOwnProperty.call(config.headers.common, 'Authorization')) {
        // console.log("previous axios");
        config.headers.common['Authorization'] = `Bearer ${getAccessToken()}`;
        hasAuthorization = true;
      }
    }
    if (hasAuthorization) {
      // check if login request or get general info request
      if (
             config.url.endsWith(process.env.VUE_APP_API_INFO_URL)
          && config.url.endsWith(process.env.VUE_APP_API_LOGIN_URL)
          ) {
          // check if currenct user info and the permissions exist and are latest
          const validAccessData = await store.dispatch('CheckValidAccessData')
          if (!validAccessData) {
            console.log("GetUserInfo");
            // get user info and renew the permissions
            await store.dispatch('GetUserInfo')
          }

      }
    }
    return config
  },
  error =>
    // do something with request error
    Promise.reject(error)

)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code.
   */
  response => {
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.code > 399) {
      //store the original path for redirection
      // store.commit('page/setRedirectPath', window.location.href)
      Vue.prototype.$notify.error({
        title: 'Error',
        message: 'Login timeout, please login again',
        position: 'top-right',
      })
      store.dispatch('FedLogOut').then(() => {
        // for timeout case, don't reload so that redirect path can keep
        // location.reload()// 为了重新实例化vue-router对象 避免bug
      })
      // Notify({ type: 'danger', message: res.data.error.message || 'error' })
      return Promise.reject(res.statusText || 'error')
    }
    return res
  },
  error => {
    console.log({ error })
    if (axios.isCancel(error)) {
      // 为了终结promise链 就是实际请求 不会走到.catch(rej=>{});这样就不会触发错误提示之类了。
      return new Promise(() => {})
    }
    if (error.message.includes('timeout')) {
      Vue.prototype.$notify.error({
        title: 'Error',
        message: 'Network connection error, please try again',
        position: 'top-right',
      })
    } else if (!error.response || error.response.status === 500) {
        Vue.prototype.$notify.error({
        title: 'Error',
        message: 'Network connection failure',
        position: 'top-right',
        color: 'danger'
      })
    } else if (error.response.status === 401) {
      let errorData = error.response?.data?.message;
      let errorKey = errorData?.error ?? "";
      let errorCode  = errorData?.message ?? "";
			let loginUrl = process.env.VUE_APP_LOGIN_URL;
      if (errorKey && errorCode) {
        // only handle auth error defined
        Vue.prototype.$notify.error({
          title: i18n.t("login.msg.error"),
          message: i18n.t("login.msg." + errorKey) +" (" + errorCode + ")" ,
          position: 'top-center',
          color: 'danger'
        })
        if (loginUrl != router.currentRoute.fullPath) {
          router.push({ path: `Login?path=${router.currentRoute.fullPath}` })
        }
      }
    } else {
      // hide other case
      // console.log(error.response.data);
      // Vue.prototype.$notify.error({
      //   title: 'Error',
      //   message: error.response.data.message,
      //   position: 'top-right',
      // })
    }
    return Promise.reject(error)
  }
)

export default service
