export default {
  common: {
    login: '登录',
    email:'电子邮件地址',
    password:'密码',
    system:'设计资源系统',
    forgetPassword:'忘记密码？',
    rember:'记住我 ',
	  adminCenter:'管理中心',
    Load: '正在加载中',
    loadmore: '载入更多',
    loadover:'(已加载全部数据)',
    headsearch:'搜索',
    userreview:'我的评分',
    changepassword:'更改密码',
    loginout:'登出',
    language:'语言',
    noData:'沒有匹配的结果!',
    result_none: "没有结果",
    inform: "我的查询清单",
    wishlist:'我的珍藏',
    metaname:"设计资源系统",
    lib_file: "档案下载",
    all_cat: "全部分类",
    prodCategory:'全部产品分类',
    wishCategory:'全部珍藏分类',
    sent: "已发送",
    draft: "未发送",
    details: "详情",
    confirm_to_del: "确认删除？",
    confirm: "确认",
    cancel: "取消",
    copyright: "版权所有",
    action: "发送重置链接",
    message: "返回登录页",
    news: "新发布",
    year:"年份",
    search_results: "搜寻结果",
    no_result: "没有匹配的结果",
    product:"物料产品",
    my_review: "我的评分",
    to_be_rated: "待评分",
    rated: "已评分",
    supplier_rating: "供应商评分",
    rate: "评分",
    ratings_success: "评分成功！",
    ratings_mandatory: "相关字段必须填写。",
    ratings_mandatory_rating:"评分字段是必需的。",
    select_region: "请选择适用地区 / 项目地区",
    forHK: "香港",
    forCN: "中国内地",
    forOS: "海外",
    resetPassword:'重设密码',
    new_products: "最新产品",
    recently_viewed: "最近检视的产品",
    product_cat: "按产品分类",
    sortOption:[
      {
        value:'',
        text:'排序方式'
      },{
        value:'asc',
        text:'按升序'
      },{
        value:'desc',
        text:'按降序'
      },
    ],

  },
  login: {
    msg: {
      error: "登录错误",
      success: "登录成功!",
      fail: "登录电邮/密码错误！",
      inactive: "登录电邮已停止使用！",
      expired: "登录电邮已过期！",
      use_chrome: "请使用谷歌(Chrome)浏览器登录",
      no_mobile_no: "系统找不到您的手机号",
      invalid_user: "邮箱或密码不正确",
      no_input: "请输入邮箱和密码",
      no_input_veri: "请输入所有栏位",
      no_external_login_right: "没有外部登录授权",
      invalid_code_or_expired: "验证码错误或已过期",
      invalid_login_code: "登录验证码错误",
      failed_send_vericode: "暂时无法发放验证码, 请稍后再试",
      failed_check_vericode: "系统暂时无法验证, 请稍后再试",
      too_many_attempts: "尝试次数过多, 请稍后再试",
    }
  },
  productfilter:{
    product_tbc:"查询(另议)",
    products: "产品",
    Highlight:'重点',
    Category:'分类',
    FitOut:'硬装产品',
    Furnishings:'软装产品',
    filter_subcat: "副分类",
    Brand:'品牌',
    Series:'系列',
    Color:'颜色',
    MainMaterials:'主要材质',
    Library:'设计风格',
    retailprice:'參考零售价',
    costprice:'參考成本价',
    Size:'尺寸',
    Leadtime:'周期',
    Day:'日',
    Lastupdate:'最后更新',
    Produtype:'跨界合作品牌产品',
    inc_ship_cost: "价格已含运费",
    inc_vat: "价格已含税",
    contactinfo:'联系资料',
    contact_project_info: "项目资料",
    contact_product_info: "产品资料",
    contact_mobile: "手机号码",
    contact_wechat: "微信号",
    contact_request: "要求提供",
    Vendor:'联系厂家 / 供应商',
    Contactperson:'联系人',
    Telephone:'电话',
    Email:'电邮地址',
    projectNum:' 选择项目编号',
    Attachment:'附件',
    Toemail:'抄送邮件到',
    Myphone:'我的手机号码',
    Wechat:'我的微信号',
    time:'回复/汇报时间',
    Addequiry:'添加到查询清单',
    Send:'发送',
    Cancel:'取消',
    project1:'包含项目名称',
    project2:'包含客户名称',
    project3:'提供样板',
    project4:'提供方案',
    msg:'列明需查询产品 例: 039 页: LW47245B',
    checked:'全选',
    checkednum:'被选中',
    Region:'适用地区',
    orderby:'排序方式',
    pageShow: '每页显示',
    pictureShows:'按类显示',
    pictureOptions:[{
        value:null,
        text:'预设显示'
      },{
        value:1,
        text:'产品目录'
      },{
        value:0,
        text:'产品'
      }],
    regionption:[
      {
        value:'HK',
        text:'香港'
      },{
        value:'CN',
        text:'中国內地'
      },{
        value:'OS',
        text:'海外'
      }
    ],
    sortSelectOptions: [
      {
        text:'预设排序',
        val:'default'
      },{
        text:'最新',
        val:'newest'
      },{
        text:'价格从低到高',
        val:'priceasc'
      },{
        text:'价格从高至低',
        val:'pricedesc'
      },{
        text:'访问次数从高至低',
        val:'pageview'
      },{
        text:'珍藏次数从高至低',
        val:'wishlist'
      }

    ],
    unit:'项',
    resultxt:'的搜索结果',
    moreDatail:'更多细节...',
    description:'描述/规格',
    brand_products: "跨界合作品牌产品",
    new_brand_products: '最新跨界合作品牌产品',
    own_products: "自家研发产品",
    strategic_products: "战略合作品牌产品",
    recommended_products: "认可合作品牌产品",
    registered_products: "协议合作品牌产品",
    strategic_brands: "战略合作品牌",
    contact_alert_select_project: "请先选择一个项目！",
    recommended_brands: "认可品牌",
    registered_brands: "协议合作品牌",
    remark: "备注",
    fit_out_products: "硬装产品",
    decorative: "装饰品",
    specialized_companies: "供应商",
    design_styles: "设计风格",
    Confirm:'确定',
    NotCategory:'不选择分类',
    CreateCategory:'新建分类',
    DefaultCategory:'默认分类',
    CategoryName:'分类名称',
    AddBtn:'添 加',
    UpdateBtn:'修 改',
    UpdateName:'修改分类名称',
    DelCategory:'刪除分类',
    ConfirmDel:'是否刪除分类下的所有珍藏',
    mobileFilter:'物料 / 产品过滤器',
  },
  programOptions: [
    {
      type: 1,
      text: "包含项目名称"
    },
    {
      type: 2,
      text: "包含客戶名称"
    },
    {
      type: 3,
      text: "提供样板"
    },
    {
      type: 4,
      text: "提供方案"
    }
  ],
  navData:[
    {
      url:'/promotions',
      text:'精选推介',
      children:[
        {
          url:'/promotions/1',
          text:'梁志天私人珍藏',
        },{
          url:'/promotions/2',
          text:'司徒志明先生艺术作品',
        }
        ,{
          url:'/privateresidence',
          text:'私宅设计专区',
        }
      ]
    },{
      url:'/sdx-brand',
      text:'跨界合作',
      children:[]
    },{
      url:'/strategic',
      text:'战略合作',
      children:[
        {
          url:"/strategic/brand?type=brands",
          text:'品牌',
        },{
          url:"/strategic/brand?type=distributors",
          text:'代理商',
        }
      ]
    },{
      url:'/approved',
      text:'认可合作',
      children:[
        {
          url:"/approved/brand?type=brands",
          text:'品牌',
        },{
          url:"/approved/brand?type=distributors&loc=CN",
          text:'中国代理商',
        },{
          url:"/approved/brand?type=distributors&loc=HK",
          text:'香港代理商',
        }
      ]
    },{
      url:'/product',
      text:'物料 / 产品',
      children:[
        {
          text:'自家研发产品',
          childnode:[
            {
              url:`/product?hl=2&selectcat=2`,
              catId:2,
              text:'活动家具'
            },{
              url:`/product?hl=2&selectcat=3`,
              catId:3,
              text:'装饰灯具'
            },{
              url:`/product?hl=2&selectcat=4&selectsubcat=229`,
              text:'活动地毯'
            },{
              url:`/product?hl=2&selectcat=393`,
              text:'艺术装置'
            },{
              url:'',
              text:'装饰品'
            }
          ]
        },{
          text:'软装产品',
          childnode:[
            {
              url:`/product?selectcat=2`,
              text:'活动家具'
            },{
              url:`/product?selectcat=3`,
              text:'装饰灯具'
            },
            {
              url:'',
              text:'窗帘'
            },{
              url:`/product?selectcat=4&selectsubcat=229`,
              text:'活动地毯'
            },{
              url:`/product?selectcat=393`,
              text:'艺术装置'
            },{
              url:`/product?selectcat=5`,
              text:'织物'
            },{
              url:`/product?selectcat=6`,
              text:'装饰品'
            },{
              url:`/product?selectcat=362`,
              text:'布料, 皮, 人造皮'
            }
          ]
        },{
          text:'硬装产品',
          childnode:[
            {
              url:`/product?selectcat=319`,
              text:'石材'
            },{
              url:`/product?selectcat=315`,
              text:'砖及马赛克'
            },{
              url:`/product?selectcat=304`,
              text:'地板'
            },{
              url:`/product?selectcat=4`,
              text:'地毯'
            },{
              url:`/product?selectcat=298`,
              text:'护墙板'
            },{
              url:`/product?selectcat=151`,
              text:'墙纸'
            },{
              url:`/product?selectcat=384`,
              text:'特色面料'
            },{
              url:`/product?selectcat=367`,
              text:'油漆'
            },{
              url:`/product?selectcat=322`,
              text:'门'
            },{
              url:`/product?selectcat=73`,
              text:'五金'
            },{
              url:`/product?selectcat=355`,
              text:'玻璃及鏡'
            },{
              url:`/product?selectcat=362`,
              text:'布料, 皮, 人造皮'
            },{
              url:`/product?selectcat=161`,
              text:'木饰面'
            },{
              url:`/product?selectcat=158`,
              text:'金属饰面'
            },{
              url:`/product?selectcat=359`,
              text:'耐火板'
            },{
              url:`/product?selectcat=376`,
              text:'固定家具'
            },{
              url:`/product?selectcat=68`,
              text:'插座及开关'
            },{
              url:`/product?selectcat=69`,
              text:'建筑灯具'
            },{
              url:`/product?selectcat=342`,
              text:'窗帘系统'
            },{
              url:'',
              text:'窗帘'
            },{
              url:`/product?selectcat=299`,
              text:'医养产品'
            }
          ]
        },{
          text:'卫浴产品',
          childnode:[
            {
              url:`/product?selectcat=311&selectsubcat=66`,
              text:'卫浴洁具'
            },{
              url:`/product?selectcat=311&selectsubcat=72`,
              text:'卫浴龙头'
            },{
              url:`/product?selectcat=311&selectsubcat=56`,
              text:'卫浴配件'
            },{
              url:`/product?selectcat=311&selectsubcat=324`,
              text:'淋浴房'
            },{
              url:`/product?selectcat=311&selectsubcat=402`,
              text:'卫生间隔断'
            }
          ]
        },{
          text:'厨房产品',
          childnode:[
            {
              url:`/product?selectcat=306&selectsubcat=307`,
              text:'厨房水槽'
            },{
              url:`/product?selectcat=306&selectsubcat=308`,
              text:'厨房龙头'
            },{
              url:`/product?selectcat=306&selectsubcat=309`,
              text:'厨房配件'
            },{
              url:`/product?selectcat=306&selectsubcat=310`,
              text:'厨柜'
            }
          ]
        },{
          text:'电器 / 设备',
          childnode:[
            {
              url:'/product?selectcat=263',
              text:'智能家居系统'
            },{
              url:'/product?selectcat=306&selectsubcat=316',
              text:'厨房电器'
            },{
              url:'/product?selectcat=329',
              text:'洗衣间电器'
            },{
              url:'/product?selectcat=285',
              text:'健身设备'
            },{
              url:'/product?selectcat=283',
              text:'保安设备'
            },{
              url:'/product?selectcat=325',
              text:'火炉'
            },{
              url:'/product?selectcat=369',
              text:'家庭影院系统'
            },{
              url:'/product?selectcat=295',
              text:'专业供应商系统'
            }
          ]
        }
      ]
    },{
      url:'/specialist',
      text:'供应商',
      children:[
        {
          text:'软装供应商',
          childnode:[
            {
              url:'/supplier?id=1&name=软装供应商',
              text:'成品供应商'
            },{
              url:'/supplier?id=2&name=软装供应商',
              text:'定制品供应商'
            },{
              url:'/supplier-cats?catId=3&name=软装供应商',
              text:'第三方服务供应商'
            }]
        },{
          text:'专业制作公司',
          childnode:[
            {
              url:'/supplier-companylist?cl=3&ct=14',
              text:'效果图'
            },{
              url:'/supplier-companylist?cl=3&ct=15',
              text:'扩初招标图 / 施工深化图'
            },{
              url:'/supplier-companylist?cl=3&ct=16',
              text:'施工图'
            },{
              url:'/supplier-companylist?cl=3&ct=17',
              text:'二次机电图'
            },{
              url:'/supplier-companylist?cl=3&ct=18',
              text:'VR/AR'
            },{
              url:'/supplier-companylist?cl=3&ct=19',
              text:'BIM'
            },{
              url:'/supplier-companylist?cl=3&ct=20',
              text:'二级摄影'
            },{
              url:'/supplier-companylist?cl=3&ct=37',
              text:'专业摄影'
            },{
              url:'/supplier-companylist?cl=3&ct=36',
              text:'图文印刷'
            }
          ]
        },{
          text:'专业顾问',
          childnode:[
            {
              url:'/supplier-companylist?cl=2&ct=42',
              text:'建筑'
            },
            // {
            //   url:'',
            //   text:'结构'
            // },
            {
              url:'/supplier-companylist?cl=2&ct=2',
              text:'灯光'
            },{
              url:'/supplier-companylist?cl=2&ct=52',
              text:'二级灯光'
            },{
              url:'/supplier-companylist?cl=2&ct=3',
              text:'机电'
            },{
              url:'/supplier-companylist?cl=2&ct=5',
              text:'工料测量'
            },{
              url:'/supplier-companylist?cl=2&ct=4',
              text:'园林景观'
            },{
              url:'/supplier-companylist?cl=2&ct=6',
              text:'标识'
            },{
              url:'/supplier-companylist?cl=2&ct=10',
              text:'声学'
            },
            // {
            //   url:'',
            //   text:'燃气系统'
            // },
            {
              url:'/supplier-companylist?cl=2&ct=7',
              text:'可持续性发展'
            },{
              url:'/supplier-companylist?cl=2&ct=8',
              text:'安保'
            },{
              url:'/supplier-companylist?cl=2&ct=44',
              text:'资讯科技及影音'
            },{
              url:'/supplier-companylist?cl=2&ct=45',
              text:'停车场规划'
            },{
              url:'/supplier-companylist?cl=2&ct=49',
              text:'VR/AR 互动体验'
            },{
              url:'/supplier-companylist?cl=2&ct=53',
              text:'平面设计'
            }
          ]
        },{
          text:'专业设备 / 系统供应商',
          childnode:[
            {
              url:'',
              text:'安保系统'
            },{
              url:'/supplier-companylist?cl=1&ct=22',
              text:'智能家居系统'
            },{
              url:'/supplier-companylist?cl=1&ct=23',
              text:'装甲门'
            },{
              url:'',
              text:'泳池过滤系统'
            },{
              url:'',
              text:'水疗设备'
            },{
              url:'/supplier-companylist?cl=1&ct=24',
              text:'酒窖设备'
            },{
              url:'/supplier-companylist?cl=1&ct=25',
              text:'净水系统'
            },{
              url:'/supplier-companylist?cl=1&ct=26',
              text:'影音系统'
            },{
              url:'',
              text:'无线网络系统'
            },{
              url:'/supplier-companylist?cl=1&ct=27',
              text:'空气净化系统'
            },{
              url:'/supplier-companylist?cl=1&ct=28',
              text:'专业厨房设备'
            },{
              url:'/supplier-companylist?cl=1&ct=35',
              text:'健身器材'
            },{
              url:'/supplier-companylist?cl=1&ct=29',
              text:'健康设计系统'
            },{
              url:'/supplier-companylist?cl=1&ct=31',
              text:'地板采暖系统'
            },{
              url:'/supplier-companylist?cl=1&ct=46',
              text:'数字化工作空间解决方案'
            },{
              url:'/supplier-companylist?cl=1&ct=47',
              text:'智能酒店系统'
            },{
              url:'/supplier-companylist?cl=1&ct=48',
              text:'安全屋'
            },{
              url:'/supplier-companylist?cl=1&ct=51',
              text:'展览系统'
            },{
              url:'/supplier-companylist?cl=1&ct=54',
              text:'家庭绿电 (墅式光储能)'
            }
          ]
        },{
          text:'施工单位',
          childnode:[
            {
              url:'/supplier-companylist?cl=5&ct=43',
              text:'施工单位'
            },
          ]
        }
      ]
    },{
      url:'/guideline?mid=3',
      text:'项目分享',
      children:[
        {
          url:'/deliverable',
          text:'设计成果'
        },{
          url:'/guideline?mid=20',
          text:'项目照片'
        },{
          url:'/design-guideline?mid=8',
          text:'设计指引'
        }
      ]
    },{
      url:'/design-guideline?mid=1',
      text:'设计风格',
      children:[
        {
          url:'/project-file?mid=1&id=801',
          text:'最新设计风格',
          role_needed: '66'
        },{
          url:'/project-file?mid=1&id=1037',
          text:'2023 设计风格'
        },{
          url:'/project-file?mid=1&id=1015',
          text:'2022 设计风格'
        },{
          url:'/project-file?mid=1&id=757',
          text:'2021 设计风格'
        },{
          url:'/project-file?mid=1&id=4',
          text:'2020 设计风格'
        },{
          url:'/project-file?mid=1&id=69',
          text:'2019 设计风格'
        },{
          url:'/project-file?mid=1&id=5',
          text:'2018 设计风格'
        },{
          url:'/project-file?mid=1&id=3',
          text:'2017 设计风格'
        },{
          url:'/project-file?mid=1&id=2',
          text:'2016 设计风格'
        },{
          url:'/project-file?mid=1&id=1',
          text:'2015 设计风格'
        }
      ]
    },{
      url:'/design-guideline?mid=20',
      text:'项目标准文件',
      children:[
        {
          url:'/project-file?mid=20&id=58',
          text:'SLD 设计说明格式'
        },{
          url:'/project-file?mid=20&id=56',
          text:'SLD 汇报文档格式'
        },{
          url:'/project-file?mid=20&id=977',
          text:'SL2.0 设计说明格式'
        },{
          url:'/project-file?mid=20&id=930',
          text:'SL2.0 汇报文档格式'
        },{
          url:'/project-file?mid=20&id=709',
          text:'SLL 设计说明格式'
        },{
          url:'/project-file?mid=20&id=929',
          text:'SLL 汇报文档格式'
        },{
          url:'/project-file?mid=20&id=727',
          text:'SLL 清单格式'
        },{
          url:'/project-file?mid=20&id=59',
          text:'技术说明'
        },{
          url:'/project-file?mid=20&id=57',
          text:'绘图指引'
        },{
          url:'/project-file?mid=20&id=60',
          text:'图纸目录格式'
        },{
          url:'/project-file?mid=20&id=61',
          text:'出图记录格式'
        },{
          url:'/project-file?mid=20&id=708',
          text:'物料 / 产品应用表格式'
        },{
          url:'/project-file?mid=20&id=62',
          text:'物料样板格式'
        }
      ]
    },{
      url:'/guideline??mid=6',
      text:'学习资源',
      children:[
        {
          url:'/design-guideline?mid=30',
          text:'SLD Talk'
        },{
          url:'/design-guideline?mid=31',
          text:'品牌产品介绍视频'
        },{
          url:'/design-guideline?mid=6',
          text:'设计参考'
        },{
          url:'/design-guideline?mid=33',
          text:'展览分享'
        },{
          url:'/design-guideline?mid=3',
          text:'技术参考'
        },{
          url:'/design-guideline?mid=7',
          text:'公开演讲'
        },{
          url:'/design-guideline?mid=22',
          text:'健康设计'
        },{
          url:'/project-file?mid=35&id=66',
          text:'设计考虑'
        },{
          url:'/design-guideline?mid=23',
          text:'物料 / 产品指引'
        },{
          url:'/design-guideline?mid=36',
          text:'精装交楼标准设计'
        },{
          url:'/design-guideline?mid=37',
          text:'汇报技巧分享会'
        },{
          url:'/design-guideline?mid=39',
          text:'营销培训分享会'
        },{
          url:'/design-guideline?mid=40',
          text:'内部培训分享会'
        },{
          url:'/design-guideline?mid=41',
          text:'SLD 年度大奖颁奖典礼'
        },{
        //   url:'/design-guideline?mid=42',
        //   text:'迎新资料'
        // },{
          url:'/design-guideline?mid=43',
          text:'中央管理制度会议'
        },{
          url:'/design-guideline?mid=45',
          text:'场景展示方案'
        },{
        //   url:'/design-guideline?mid=46',
        //   text:'杂志'
        // },{
          url:'/design-guideline?mid=47',
          text:'艺术品分享'
        }
      ]
    }
  ],
  supplierfilter:{
    supplietype1:'软装供应商',
    supplietype2:'专业制作公司',
    supplietype3:'专业顾问',
    supplietype4:'专业设备 / 系统供应商',
    supplietype5:'施工单位',
    Suppliers:'供应商',
    product_brand:'经营品牌',
    nodata:'沒有结果',
    pricerenders:'效果图制作公司费用总览',
    pricerendersPRC:'2024 效果图单价总览 (国内)',
    pricerendersThai:'2024 效果图单价总览 (泰国)',
    reviewsrenders:'效果图制作公司服务质量总览',
    designdraffers:'扩初图制作公司费用总览',
    reviewdesigndraffers:'扩初图制作公司服务质量总览',
    rating:'位用户评分',
    userreview:'用戶评分',
    score:'评分',
    year:'年份',
    min:'最低',
    avg:'平均',
    max:'最高',
    isnew:'最新',
    contact_lang:'联络语言',
    company_size:'规模(员工人数)',
    price_rate:'取费标准',
    company_intro:'公司简介',
    recommend_by:'推荐人',
    ware_date:'入库日期',
    sld_project:'最近参与的20个SLDG项目',
    num_project_pre:'最近参与的',
    num_project_beh:'个SLDG项目',
    writeBtn:'撰写评论',
    rating_target:'评论对象:',
    rating_target_type:'评论对象类型',
    supplier_rating:'供应商评分',
    rating_project:'项目',
    rating_rate: '评分',
    rating_review:'评论',
    optional:'(可选填)',
    upload_attartch:'上传附件',
    submit:'提交',
    related_document:'相关文档',
    please_select: '请选择',
    upload_drag: "拖放文件在这里上传",
    upload_width: "阔度下限",
    upload_height: "长度下限",
    upload_limit: "仅支持JPG和PNG图像！",
    upload_size:"上传的图片大小不能超过2MB！",
  },
  Promotions:{
    promotions: "精选推介",
    steve: "梁志天私人珍藏",
    marco: "司徒志明先生艺术作品",
    type_private_house: "私宅设计专区",
    production_companies: "专业制作公司",
    equip_sys_suppliers: "专业设备 / 系统供应商",
  },
  sdxBrand:{
    san_products: "卫浴产品",
    contact_vendor: "联系厂家 / 供应商",
    download_product_cat: "下载产品目录",
    contact_vendors: "联系供应商",
    direct_distributor:'直营/经销商',
    store_visiting:'预约实体店',
    furniture: "活动家具",
    lighting: "装饰灯具",
    see_all: "查看全部",
    discontinued: "已停产",
  },
  Strateg:{
    strategic: "战略合作",
    brands: "品牌",
    distributors: "代理商",
    product_cat: [
      {
        name:"按产品分类",
        id:null
      }
    ],
    header_company_website: "公司网页",
    ta_erp: "创意家居 ERP 链接",
    ta_erp_doc: "ERP 系统操作指引",
    header_online_store: "网上商店",
    header_product_catalogue: "产品目录",
    projectType1: [
      {
        type: 1,
        text: "包含项目名称"
      },
      {
        type: 2,
        text: "包含客戶名称"
      }

    ],
    projectType2: [
      {
        type: 3,
        text: "提供样板"
      },
      {
        type: 4,
        text: "提供方案"
      }
    ],
    contact_preview: "预览",
    approved_china_distributors: "认可合作中国代理商",
    approved_hk_distributors: "认可合作香港代理商",
    strategic_distributors: "战略合作代理商",
    strategic_brands:"战略合作品牌",
  },
  approved:{
    approved: "认可合作",
    approved_brands: "认可合作品牌",
    brands: "品牌",
    china_distributors: "中国代理商",
    hk_distributors: "香港代理商",
  },
  welcome:{
    project_sharing: "项目分享",
    design_sharing:'设计分享',
    speech_guidance: "演讲 / 指导",
    learning_resources: "学习资源",
    project_photos: "项目照片",
    news: "新发布",
    sortby: "排序方式",
    sort_desc_update: "按更新时间降序",
    sort_asc_update: "按更新时间升序",
    added_success: "成功！",
    added_fail: "失败！",
    added_to_cart: "已添加产品。",
    fail_to_add_product: "不允许添加产品。",
    send_email:'正在发送邮件',
    send_email_fail:'发送邮件失败!',
    design_deliverable: "设计成果",
    design_guidelines: "设计指引",
    project_code: "项目编号",
    project_location: "项目地点",
    project_client: "客方名称",
    project_cost_fitting_out: "硬装造价",
    project_cost_ffne: "软装造价",
    project_rmb_per_sqm: "平方米",
    project_scope: "经营范围",
    project_type: "项目类型",
    project_photo_shooting_pro: "专业摄影",
    project_photo_shooting_second: "二级摄影",
    project_photo_shooting_internal: "内部摄影",
    guildline: "设计指引",
    design_style:"设计风格",
    project_grade_general: "一般项目",
    project_grade_important: "重点项目 (#)",
    project_grade_quality: "优质项目 (*)",
    project_grade_premium: "特选项目 (**)",
    project_grade: "项目级别",
    project_contractor: "施工单位",
    project_design_scope: "设计范围",
    project_design_area: "设计面积",
    project_requirements: "设计要求",
    project_team_construct: "建筑团队",
    project_team_interior: "室内设计团队",
    project_team_four_cat: "四大件设计团队",
    project_team_ffne: "饰品设计团队",
    project_team_implement: "软装实施团队",
    project_budget_construct: "建筑成本预算",
    project_cost_construct: "建筑造价",
    project_budget_fitting_out: "硬装成本预算",
    project_equip_excluded: "不含设备",
    project_budget_ffne: "软装成本预算",
    project_new_style: "新设计风格的应用",
    project_photo_shooting: "项目摄影",
    file_preview_download: "档案预览/下载",
    file_no_external: "只限公司內部網絡可見",
    select_all: "全选",
    download: "下载",
    album_statement: "此文件夹中的照片只能作为参考用途，切勿抄袭或在汇报使用",
    related_url: "相关产品/资讯链接",
    view_count: "观看次数",
    pdf_error:'网络异常，文件获取失败'
  },
  designresinfo:{
    speaker: "演讲者",
    date: "时间",
    place: "地点",
    event_name: "活动名",
    event_org: "主办单位",
    theme: "演讲主题",
    people: "其他嘉宾",
    audience: "主要受众",
    brief: "内容概要",
    standard_project_doc: "项目标准文件",
    rate: "评分",
    no_record: "没有记录",
    whoview: "观看者名单",
    viewname: "观看者名字",
    viewcount: "观看次数",
    lastview: "最后一次观看",
    confirm: "确认",
  }

}
